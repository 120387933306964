@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "@core/scss/angular/libs/blockui.component";

@font-face {
  font-family: "TH Sarabun PSK";
  src: url("~/assets/fonts/THSarabun.ttf") format("truetype");
  /* Path to the regular font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TH Sarabun PSK";
  src: url("~/assets/fonts/THSarabun-Bold.ttf") format("truetype");
  /* Path to the bold font file */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TH Sarabun PSK";
  src: url("~/assets/fonts/THSarabun-Italic.ttf") format("truetype");
  /* Path to the italic font file */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "TH Sarabun PSK";
  src: url("~/assets/fonts/THSarabun-Bold-Italic.ttf") format("truetype");
  /* Path to the bold italic font file */
  font-weight: bold;
  font-style: italic;
}

.modal .modal-header .close {
  box-shadow: unset;
  background: unset;
  transform: unset;
  padding: 0.8rem 1.4rem;
}

.modal .modal-header .close:hover {
  transform: unset;
}

.modal .modal-header {
  background-color: #283792;
}

.modal .modal-header {
  background-color: #283792;
  color: white;

  h5 {
    color: white;
  }

  span {
    color: white;
  }

  .modal-title {
    color: white
  }
}

.text-end {
  text-align: end !important;
}

textarea:not(.doc-table textarea) {
  resize: none;
}

.doc-table textarea {
  max-height: 500px;
}

a.disabled {
  color: gray;
  cursor: not-allowed;
  text-decoration: underline;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #efefef !important;
}

.ng-option-disabled {
  background-color: #efefef !important;
}

.btn-menu.disabled {
  background: lightgrey !important;
}
.text-truncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-truncate-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-underline {
  text-decoration: underline;
}

.text-pink {
  color: #e323a3;
}

.modal-footer {
  justify-content: space-between !important;
}

.new-document-btn.disabled {

  line-height: 0.4 !important;
}

.accountant-table, .non-accountant-table {
  .ng-select.ng-select-single .ng-select-container {
    height: 2.714rem !important;
  }
}

.ng-select.is-invalid.error {
  .ng-select-container {
    border-color: red !important;
  }
}

.timepicker-table {
  table th,
  table td {
    padding: 0.72rem 0rem !important;
  }
}

.popover.bs-popover-bottom .arrow:after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: transparent !important;
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: transparent !important;
}

